import { useMediaQuery } from '@itau-loans-www/shopping/src/hooks'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import { If } from '@itau-loans-www/shopping/src/components'
import Layout from '@itau-loans-www/shopping/src/layouts/Summary'
import { useLead } from '@itau-loans-www/shopping/src/hooks'

import '../final-screen.scss'
import DesktopHeader from './components/DesktopHeader'
import MobileHeader from './components/MobileHeader'

import { useResult } from '../hooks'

const ConsignadoSuccess = () => {
  const {
    leadData: { name },
  } = useLead()

  useResult({})
  const isMobile = useMediaQuery('mobileLandscape')
  const proposalContext = useProposal()
  const proposal = proposalContext?.product?.proposal
  const link = proposal?.link
  const productCode = proposal?.productCode


  return (
    <Layout>
      <div className="final_screen">
        <If
          condition={isMobile}
          renderIf={
            <div className="final_screen__content">
              <MobileHeader link={link} productCode={productCode} name={name} />
            </div>
          }
          renderElse={<DesktopHeader link={link} name={name} />}
        />
      </div>
    </Layout>
  )
}

export default ConsignadoSuccess
