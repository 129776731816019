import { IdsMainButton } from '@ids/react'
import {
  SeeOffersButton,
  TimeLine
} from '@itau-loans-www/shopping/src/components'
import analytics from '@mobi/libraries/analytics'

import { STEP_DATA } from '../../constants'

const MobileHeader = ({ link, productCode, name }) => {
  return (
    <>
      <h1 className="final_screen__title">{`${name}, identificamos que você é cliente Itaú. Acesse o app e finalize seu empréstimo consignado`}</h1>

      <p className="final_screen__text">
        Ao clicar no botão, você será direcionado ao app para continuar sua
        simulação
      </p>

      <div className="final_screen__buttons-wrapper">
        <a href={link} target="_blank" rel="noreferrer">
          <IdsMainButton
            full
            onClick={() => {
              analytics.track('buttonClick', {
                currentPage: 'telas-finais-de-redirect-do-produto',
                detail: productCode
              })
            }}
          >
            Abrir o app
          </IdsMainButton>
        </a>
        <SeeOffersButton isCrossSell />
      </div>

      <TimeLine title="Confira os próximos passos" steps={STEP_DATA} />
    </>
  )
}

export default MobileHeader
