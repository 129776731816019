import { useState, useEffect } from 'react'

import generateQRCode from '@mobi/utils/events/qrcode'

const useDesktopHeader = ({ link }) => {
  const [qrCode, setQrCode] = useState('')

  const handleGenerateQR = () => {
    generateQRCode(link, { margin: 0 }, (err, url) => setQrCode(url))
  }

  useEffect(() => {
    handleGenerateQR()
  }, [])

  return {
    qrCode
  }
}

export { useDesktopHeader }
