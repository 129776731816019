import {
  SeeOffersButton,
  TimeLine
} from '@itau-loans-www/shopping/src/components'

import { STEP_DATA } from '../../constants'
import { useDesktopHeader } from './hooks'

const DesktopHeader = ({ link, name }) => {
  const { qrCode } = useDesktopHeader({ link })

  return (
    <div className="final_screen__content">
      <h1 className="final_screen__title">
        {`${name}, identificamos que você é cliente Itaú. Acesse o app e finalize seu empréstimo consignado`}
      </h1>
      <p className="final_screen__text">
        aponte a câmera do seu celular para o QR code para continuar sua
        simulação.
      </p>
      <img src={qrCode} alt="QR Code" />
      <div className="final_screen__buttons-wrapper">
        <SeeOffersButton isCrossSell />
      </div>

      <TimeLine title="Confira os próximos passos" steps={STEP_DATA} />
    </div>
  )
}

export default DesktopHeader
